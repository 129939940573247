import React, { useEffect, useState } from "react";
import Layout from "pages/Layout";
import AutocitireCard from "components/Autocitire/AutocitireCard";
import DataTableAutocitire from "components/Autocitire/DataTableAutocitire";
import ComponentLoading from "components/Loaders/ComponentLoading";
import { requestJson, RequestMethod } from "helpers/httpHelper";
import NotificationCard from "components/NotificationCard";
import { getActiveNotificationPattern } from "../helpers/notificationsHelpers";
import { storageHelpers } from "helpers/storageHelpers";
export default function Autocitire() {
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [isSelfReadingsEnabled, setIsSelfReadingsEnabled] = useState({
    enabled: false,
    patterns: "",
  });
  const [showAdaugaAutocitire, setShowAdaugaAutocitire] = useState(false);
  const [selectedOption, setSelectedOption] = useState("TOATE");
  const [autocitireCardsDisplayStatus, setAutocitireCardsDisplayStatus] =
    useState({
      gaz: true,
      curent: true,
    });
  useEffect(() => {
    const initAutocitireCards = () => {
      const autocitireCardsDisplayStatusInit = {
        gaz: false,
        curent: false,
      };
      const meeterPoints = storageHelpers.getLocuriDeConsum();
      if (
        meeterPoints &&
        meeterPoints?.response?.data &&
        meeterPoints.response.data?.length
      ) {
        meeterPoints.response.data.forEach((item) => {
          if (
            item.utility_type === "Gaze" &&
            autocitireCardsDisplayStatusInit.gaz === false
          ) {
            autocitireCardsDisplayStatusInit.gaz = true;
          }
          if (
            item.utility_type === "Electricitate" &&
            autocitireCardsDisplayStatusInit.curent === false
          ) {
            autocitireCardsDisplayStatusInit.curent = true;
          }
        });
        setAutocitireCardsDisplayStatus(autocitireCardsDisplayStatusInit);
      }
    };
    const getNotificationWithInterval = async () => {
      setIsDataFetching(true);
      const response = await requestJson(
        "notification?key=selfreadings",
        RequestMethod.GET,
        { useAccessToken: true },
      );

      if (response.isSuccess) {
        const isSelfReadingIntervalActive = getActiveNotificationPattern(
          response?.response?.[0],
        );

        setIsSelfReadingsEnabled(isSelfReadingIntervalActive);
      }

      initAutocitireCards();

      setIsDataFetching(false);
    };
    getNotificationWithInterval();
  }, []);

  const onClickSendSelfReading = (type) => {
    setShowAdaugaAutocitire(true);
    setSelectedOption(type);
  };
  return (
    <Layout>
      <div className="autocitire-table-container">
        <div className="autocitire-cards-container">
          {isDataFetching ? (
            <ComponentLoading parrentClassName={"my-account-loading-div"} />
          ) : (
            <div className="d-flex flex-row">
              {autocitireCardsDisplayStatus.curent && (
                <AutocitireCard
                  type="CURENT"
                  btnDisabled={!isSelfReadingsEnabled.enabled}
                  onClickBtn={onClickSendSelfReading}
                />
              )}

              {autocitireCardsDisplayStatus.gaz && (
                <AutocitireCard
                  btnDisabled={!isSelfReadingsEnabled.enabled}
                  onClickBtn={onClickSendSelfReading}
                  type="GAZ"
                />
              )}
            </div>
          )}
        </div>
        {isSelfReadingsEnabled.enabled === true
          ? ""
          : isSelfReadingsEnabled.patterns.length > 0 && (
              <NotificationCard content={isSelfReadingsEnabled.patterns} />
            )}
        <DataTableAutocitire
          showAdaugaAutocitire={showAdaugaAutocitire}
          setShowAdaugaAutocitire={setShowAdaugaAutocitire}
          isSelfReadingsEnabled={isSelfReadingsEnabled}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      </div>
    </Layout>
  );
}
