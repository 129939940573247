import ARROR_RIGHT from "assets/images/notifications/info.svg";
import sanitizeHtml from "sanitize-html";
import { isString } from "lodash";
const NotificationCard = ({ content = "" }) => {
  if (!isString(content)) {
    return null;
  }
  const linkRegex = /<link url=\[(.*?)\]>(.*?)<\/link>/;

  const contentForDisplay = content.replace(
    linkRegex,
    (_, url, text) => `<a href="${url}" target="_blank">${text} </a>`,
  );
  const cleanHtml = sanitizeHtml(contentForDisplay, {
    allowedAttributes: {
      a: ["href", "target"],
    },
  });
  const markup = { __html: cleanHtml };
  return (
    <div className="one-notification-div">
      <p dangerouslySetInnerHTML={markup}></p>{" "}
      <img src={ARROR_RIGHT} alt="arrow_right" />
    </div>
  );
};

export default NotificationCard;
