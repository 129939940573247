import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { AppContext } from "App";
import { ReactComponent as ICON_CLOSE } from "assets/images/common/x_button.svg";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { apiNomenclature } from "api/nomenclature.service";
import { apiSuport } from "api/suport.service";
import { deschideTicketSchema } from "schemas/yupSchema";
import prepareData from "schemas/prepareDataSchema";
import objectSchema from "schemas/objectSchema";
import SelectInput from "components/SelectInput";
import DateInput from "components/DateInput";
import SubmitButton from "components/SubmitButton";
import { helpers } from "helpers/helpers";
import TextInput from "components/TextInput";
import { isArray } from "lodash";

export default function ModalDeschideTichet({
  setFetchAgainSolicitari,
  setShowDeschideTichet,
  optionsLocDeConsum,
}) {
  const { setIsScreenLoading } = useContext(AppContext);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [optionsTipFurnizare, setOptionsTipFurnizare] = useState([]);
  const [optionsSubiect, setOptionsSubiect] = useState([]);
  const [filterOptionsLocDeConsum, setFilterOptionsLocDeConsum] =
    useState(optionsLocDeConsum);

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: isFormSubmitted,
    initialValues: {
      tipTichet: "",
      tipFurnizare: "",
      subiect: "",
      locDeConsum: "",
      dataProblemei: "",
      title: "",
    },
    validationSchema: deschideTicketSchema,
    onSubmit: async (values) => {
      await postValues(values);
    },
  });

  const postValues = async (values) => {
    helpers.scrollToTop();
    setIsScreenLoading(true);
    const response = await apiSuport.postTicket(
      prepareData.deschideTicket(values),
    );
    if (response.isSuccess) {
      setFetchAgainSolicitari((prevState) => prevState + 1);
      toast.success("Tichetul a fost deschis cu succes");
    }
    //  else toast.error("A aparut o eroare la deschiderea tichetului");
    setIsScreenLoading(false);
    setShowDeschideTichet(false);
  };

  const filterOptionsTipFurnizare = (data) => {
    if (!data || !isArray(data)) {
      return [];
    }
    return data
      .filter((item) => {
        const foundUtilityTypeAsLocConsum = optionsLocDeConsum.find(
          (loc) => loc.type === item.utility_type,
        );
        if (foundUtilityTypeAsLocConsum) {
          return true;
        }
        return false;
      })
      .map((item) => {
        return {
          value: item.utility_type,
          label: item.utility_type,
          id: item.utility_type_id,
        };
      });
  };

  useEffect(() => {
    const getTipFurnizareOptions = async () => {
      const response = await apiNomenclature.getTipFurnizare();
      if (response.isSuccess) {
        setOptionsTipFurnizare(
          filterOptionsTipFurnizare(response.response.data),
        );
      }
    };
    getTipFurnizareOptions();

    const getSubiectOptions = async () => {
      const response = await apiNomenclature.getSubiect();
      if (response.isSuccess) {
        setOptionsSubiect(
          response.response.data.map((item) => ({
            value: item.subject,
            label: item.subject,
            id: item.subject_id,
          })),
        );
      }
    };
    getSubiectOptions();
  }, []);

  return (
    <>
      {ReactDOM.createPortal(
        <div className="modifica-date-container">
          <div className="modifica-date-content">
            <div className="modifica-date-header">
              <h2>Deschide tichet</h2>
              <ICON_CLOSE
                style={{ cursor: "pointer" }}
                onClick={() => setShowDeschideTichet(false)}
              />
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="modifica-date-textInput-row">
                <SelectInput
                  parrentClassName={"modifica-date-modal-select"}
                  reactSelectClassName={"modifica-date-modal-react-select"}
                  options={objectSchema.optionsSolicitareReclamatie}
                  name={"tipTichet"}
                  id={"tipTichet"}
                  labelDescription={"Tip tichet"}
                  value={formik.values.tipTichet}
                  onChange={(item) =>
                    formik.setFieldValue("tipTichet", item.value)
                  }
                  placeholder="Selectează..."
                  error={formik.errors.tipTichet}
                  touched={formik.touched.tipTichet}
                />
              </div>
              <div className="modifica-date-textInput-row">
                <SelectInput
                  parrentClassName={"modifica-date-modal-select"}
                  reactSelectClassName={"modifica-date-modal-react-select"}
                  options={optionsSubiect}
                  name={"subiect"}
                  id={"subiect"}
                  labelDescription={"Subiect"}
                  value={formik.values.subiect}
                  onChange={(item) => formik.setFieldValue("subiect", item.id)}
                  placeholder="Selectează..."
                  error={formik.errors.subiect}
                  touched={formik.touched.subiect}
                />
              </div>
              <div className="modifica-date-textInput-row">
                <h2>Detalii problema semnalata</h2>
                <TextInput
                  id={"title"}
                  name={"title"}
                  type={"text"}
                  placeholder={"Scurta descriere a problemei"}
                  classNames={"modifica-date-modal-textInput"}
                  value={formik.values.title}
                  error={formik.errors.title}
                  touched={formik.touched.title}
                  setFieldValue={formik.setFieldValue}
                />
              </div>
              <div className="modifica-date-textInput-row">
                <h2>Data problemei</h2>
                <DateInput
                  wrapperClassName="deschide-tichet-modal-datepicker-dataProblemei-wrapper"
                  pickerClassName="deschide-tichet-modal-datepicker-dataProblemei"
                  placeholderText="Selectează data..."
                  id={"dataProblemei"}
                  objectRoute={"dataProblemei"}
                  setFieldValue={formik.setFieldValue}
                  value={formik.values.dataProblemei}
                  error={formik.errors.dataProblemei}
                  touched={formik.touched.dataProblemei}
                  maxDate={new Date()} // data curenta
                />
              </div>

              <div className="modifica-date-textInput-row">
                <SelectInput
                  parrentClassName={"modifica-date-modal-select"}
                  reactSelectClassName={"modifica-date-modal-react-select"}
                  options={optionsTipFurnizare}
                  name={"tipFurnizare"}
                  id={"tipFurnizare"}
                  labelDescription={"Tip furnizare"}
                  value={formik.values.tipFurnizare}
                  onChange={(item) => {
                    formik.setFieldValue("locDeConsum", "");
                    formik.setFieldValue("tipFurnizare", item.id);
                    setFilterOptionsLocDeConsum(
                      optionsLocDeConsum.filter(
                        (loc) => loc.type === item.value,
                      ),
                    );
                  }}
                  placeholder="Selectează..."
                  error={formik.errors.tipFurnizare}
                  touched={formik.touched.tipFurnizare}
                />
              </div>

              <div className="modifica-date-textInput-row">
                <SelectInput
                  parrentClassName={"modifica-date-modal-select"}
                  reactSelectClassName={"modifica-date-modal-react-select"}
                  options={filterOptionsLocDeConsum}
                  name={"locDeConsum"}
                  id={"locDeConsum"}
                  labelDescription={"Loc de consum"}
                  value={formik.values.locDeConsum}
                  // onChange={item => formik.setFieldValue("locDeConsum", item.value)}
                  onChange={(item) => {
                    formik.setFieldValue("locDeConsum", item.value);
                  }}
                  placeholder="Selectează..."
                  error={formik.errors.locDeConsum}
                  touched={formik.touched.locDeConsum}
                />
              </div>
              <div className="modifica-date-modal-end-buttons">
                <SubmitButton
                  onClick={() => setShowDeschideTichet(false)}
                  classNames={"buton-bg-alb-green"}
                  text={"Inchide"}
                />
                <SubmitButton
                  onClick={() => setIsFormSubmitted(true)}
                  typeOfButton={"submit"}
                  text={"Salveaza"}
                />
              </div>
            </form>
          </div>
        </div>,
        document.getElementById("deschide-tichet-modal"),
      )}
    </>
  );
}
