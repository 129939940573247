import React from "react";
import { ReactComponent as BACK_BUTTON } from "assets/images/common/back_button.svg";

import { useNavigate } from "react-router-dom";
export default function LocDeConsumContainer({
  locDeConsumShowing,
  setLocDeConsumShowing,
}) {
  const navigation = useNavigate();
  const handleBack = () => {
    const currentRoute = window.location.pathname;
    if (currentRoute === "/conventie-consum") {
      navigation("/locuri-de-consum");
    } else {
      setLocDeConsumShowing({
        id: null,
        isShowing: false,
        locDeConsum: null,
      });
    }
  };

  return (
    <div className="locDeConsumContainer-header">
      <BACK_BUTTON onClick={handleBack} />
      <div className="locDeConsumHeader-header">
        <p>Loc de consum</p>
        <h2>{locDeConsumShowing.locDeConsum}</h2>
      </div>
    </div>
  );
}
