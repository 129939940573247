import { requestJson, RequestMethod } from "helpers/httpHelper";
import { storageHelpers } from "helpers/storageHelpers";

const getLocuriDeConsum = async () => {
  if (storageHelpers.getLocuriDeConsum())
    return storageHelpers.getLocuriDeConsum();

  const response = await requestJson(
    "address/metering-points",
    RequestMethod.GET,
    {
      useAccessToken: true,
    },
  );
  storageHelpers.setLocuriDeConsum(response);
  return response;
};

const getMeterSeries = async () => {
  return await requestJson("selfreadings/meters", RequestMethod.GET, {
    useAccessToken: true,
  });
};
const getLocDeConsumInfo = async (id) => {
  const response = await requestJson(
    `address/metering-point`,
    RequestMethod.GET,
    {
      useAccessToken: true,
      queryStringParams: { metering_point_id: id },
    },
  );
  return response;
};

const getConventieConsum = async (id) => {
  return await requestJson(
    `address/consumption-agreement/get`,
    RequestMethod.GET,
    {
      useAccessToken: true,
      queryStringParams: { metering_point_id: id },
    },
  );
};

const editConventieConsum = async (data) => {
  return await requestJson(
    `address/consumption-agreement/edit`,
    RequestMethod.POST,
    {
      useAccessToken: true,
      data: data,
    },
  );
};

export const apiLocuriDeConsum = {
  getLocuriDeConsum,
  getMeterSeries,
  getLocDeConsumInfo,
  getConventieConsum,
  editConventieConsum,
};
