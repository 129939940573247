import React, { useState, createContext, useEffect } from "react";
import "assets/css/main.scss";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { apiContulMeu } from "api/contulMeu.service";
import { storageHelpers } from "helpers/storageHelpers";
import ScreenLoading from "components/Loaders/ScreenLoading";

import Login from "pages/Login";
import LoggedInUser from "navigation/LoggedInUser";
import Dashboard from "pages/Dashboard";
import FacturiSiPlati from "pages/FacturiSiPlati";
import Autocitire from "pages/Autocitire";
import LocuriDeConsum from "pages/LocuriDeConsum";
import Rapoarte from "pages/Rapoarte";
import Suport from "pages/Suport";
import ContulMeu from "pages/ContulMeu";
import ResetPassword from "pages/ResetPassword";
import RecoverPassword from "pages/RecoverPassword";
import { requestJson, RequestMethod } from "helpers/httpHelper";
import ConventieConsum from "pages/ConventieConsum";
const AppContext = createContext();
console.log(process.env.REACT_APP_ENV);

function App() {
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  const [locDeConsumShowing, setLocDeConsumShowing] = useState({
    id: null,
    isShowing: false,
    locDeConsum: null,
  });
  const [userLayoutInfo, setUserLayoutInfo] = useState({
    data: {},
    fetchAgain: 0,
  });
  const [isUserLayoutInfoFetching, setIsUserLayoutInfoFetching] =
    useState(false);
  const isUserLoggedIn = storageHelpers.isUserLoggedIn();
  const [facturileMeleTableData, setFacturileMeleTableData] = useState([]);
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [soldCurent, setSoldCurent] = useState(null);
  const navigation = useNavigate();

  const getUserLayoutInfo = async () => {
    setIsUserLayoutInfoFetching(true);
    const response = await apiContulMeu.myAccountDetails();
    if (response.isSuccess) {
      storageHelpers.setUserAccount(response.response);
      setIsUserLayoutInfoFetching(false);
      setUserLayoutInfo((prevState) => ({
        ...prevState,
        data: response.response,
      }));
    } else {
      localStorage.clear();
      navigation("/");
    }
  };

  useEffect(() => {
    if (isUserLoggedIn) {
      if (storageHelpers.getUserAccount()) {
        setUserLayoutInfo((prevState) => ({
          ...prevState,
          data: storageHelpers.getUserAccount(),
        }));
      } else {
        getUserLayoutInfo();
      }
    }
  }, [isUserLoggedIn, userLayoutInfo.fetchAgain]);

  useEffect(() => {
    if (isScreenLoading) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
  }, [isScreenLoading]);

  useEffect(() => {
    const getFacturiCallback = async () => {
      if (!isUserLoggedIn) {
        return;
      }
      setIsDataFetching(true);
      const urlParams = new URLSearchParams(window.location.search);
      const invoiceIdGetParam = urlParams.get("paid_invoice_id");
      const payment_success = urlParams.get("payment_success");
      let requestUrl = "invoices/all";

      if (payment_success === "true" && invoiceIdGetParam) {
        requestUrl = `invoices/all?inProgressInvoiceId=${invoiceIdGetParam}`;
      }
      const response = await requestJson(requestUrl, RequestMethod.GET, {
        useAccessToken: true,
      });

      if (response.isSuccess) {
        setFacturileMeleTableData(response.response.data);
        setIsDataFetching(false);
        if (invoiceIdGetParam) {
          // Get the current URL
          let url = window.location.href;

          // Remove all parameters by replacing them with an empty string
          url = url.replace(/\?.*$/, "");

          // Update the URL in the browser
          window.history.replaceState({}, document.title, url);
        }
      }
    };
    const getSold = async () => {
      const response = await requestJson("invoices/total", RequestMethod.GET, {
        useAccessToken: true,
      });
      if (response.isSuccess) {
        setSoldCurent(response.response.data);
      }
    };

    if (isUserLoggedIn) {
      getFacturiCallback();
      getSold();
    }
  }, [isUserLoggedIn]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {isScreenLoading && <ScreenLoading />}

      <AppContext.Provider
        value={{
          setIsScreenLoading,
          locDeConsumShowing,
          setLocDeConsumShowing,
          userLayoutInfo,
          setUserLayoutInfo,
          isUserLayoutInfoFetching,
        }}
      >
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/recoverpassword" element={<RecoverPassword />} />
          <Route element={<LoggedInUser />}>
            <Route
              path="/dashboard"
              element={
                <Dashboard
                  isDataFetching={isDataFetching}
                  facturileMeleTableData={facturileMeleTableData}
                  soldCurent={soldCurent}
                />
              }
            />
            <Route
              path="/facturi-si-plati"
              element={
                <FacturiSiPlati
                  isDataFetching={isDataFetching}
                  facturileMeleTableData={facturileMeleTableData}
                />
              }
            />
            <Route path="/autocitire" element={<Autocitire />} />
            <Route path="/locuri-de-consum" element={<LocuriDeConsum />} />
            <Route path="/rapoarte" element={<Rapoarte />} />
            <Route path="/suport" element={<Suport />} />
            <Route path="/contul-meu" element={<ContulMeu />} />
            <Route path="/conventie-consum" element={<ConventieConsum />} />
            {/*<Route path="/recomandari" element={<Recomandari />} />*/}
          </Route>

          {/* <Route path="*" element={<NotFound404 />} /> */}
        </Routes>
      </AppContext.Provider>
    </>
  );
}

export { AppContext };
export default App;
