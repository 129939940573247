import React, { useEffect, useState } from "react";
import { requestJson, RequestMethod } from "helpers/httpHelper";
import ComponentLoading from "components/Loaders/ComponentLoading";
import NotificationCard from "components/NotificationCard";
export default function NotificationCards() {
  const [notificari, setNotificari] = useState([]);
  const [isDataFetching, setIsDataFetching] = useState(false);

  useEffect(() => {
    const getNotificarCallback = async () => {
      setIsDataFetching(true);
      const response = await requestJson(
        "notification?key=header_info",
        RequestMethod.GET,
        { useAccessToken: true },
      );
      if (response.isSuccess) {
        if (response.response.length === 0) {
          setNotificari([{ content: "Nu aveti notificari" }]);
        }
        setNotificari(response.response);
        setIsDataFetching(false);
      }
    };

    getNotificarCallback();
  }, []);

  return (
    <>
      {isDataFetching ? (
        <ComponentLoading parrentClassName={"notifications"} />
      ) : (
        <>
          {notificari.map((notificare, index) => {
            return (
              <NotificationCard key={index} content={notificare?.content} />
            );
          })}
        </>
      )}
    </>
  );
}
