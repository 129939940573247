import SubmitButton from "components/SubmitButton";
import { ReactComponent as LOCKED_ICON_SVG } from "assets/images/common/lock_locked.svg";
import { ReactComponent as UNLOCKED_ICON_SVG } from "assets/images/common/lock_unlocked.svg";
import GAZ from "assets/images/common/gaz.png";
import CURENT from "assets/images/common/curent.png";
import { helpers } from "helpers/helpers";

const tableTip = (rowData) => {
  return (
    <img
      src={rowData.utility_type === "gas" ? GAZ : CURENT}
      className="autocitire_table_tip_icon"
      alt="icon_gaz_curent"
    />
  );
};
const tableData = (rowData) => {
  return <p className="autocitire_table_data">{rowData.date}</p>;
};
const tableLocDeConsum = (rowData) => {
  return (
    <p className="autocitire_table_loc_de_consum">
      {rowData.metering_point_address}
    </p>
  );
};
const tableConsumTotal = (rowData) => {
  const unitateMasura = rowData.utility_type === "gas" ? "[mc]" : "[kWh]";
  return (
    <p className="autocitire_table_consum_total">
      {rowData.total_consumption} {unitateMasura}
    </p>
  );
};
const tableAutocitiriTrimise = (rowData) => {
  return (
    <p className="autocitire_table_autocitiri_trimise">
      {rowData.meters_no} din {rowData.meters_total_no} contoare
    </p>
  );
};

const tableSerieContor = (rowData) => {
  return (
    <p className="autocitire_table_serie_contor">{rowData.meters_series}</p>
  );
};

const tableStatus = (rowData) => {
  return (
    <>
      {rowData.status === "locked" ? (
        <div className="autocitire_table_status_div">
          <LOCKED_ICON_SVG className="autocitire_table_status_icon" />
          <p>Citit</p>
        </div>
      ) : (
        <div className="autocitire_table_status_div">
          <UNLOCKED_ICON_SVG className="autocitire_table_status_icon" />
          <p>Citit</p>
        </div>
      )}
    </>
  );
};

const header = (
  selectedOption,
  setSelectedOption,
  autocitireTableData,
  setFilteredAutocitireTableData,
  autocitiriNumber,
  setShowAdaugaAutocitire,
  exportXLSX,
  isSelfReadingsEnabled,
) => {
  return (
    <div className="autocitire_table_header">
      <div className="autocitire_table_row">
        <div className="autocitire_table_selectors">
          <div
            onClick={() => {
              setSelectedOption("TOATE");
              setFilteredAutocitireTableData(autocitireTableData);
            }}
            className={`autocitire_table_option ${
              selectedOption === "TOATE" && "autocitire_table_option-selected"
            }`}
          >
            <p>Toate autocitirile</p>
          </div>
          <div
            onClick={() => {
              setSelectedOption("CURENT");
              setFilteredAutocitireTableData(
                autocitireTableData.filter(
                  (item) => item.utility_type === "energy",
                ),
              );
            }}
            className={`autocitire_table_option ${
              selectedOption === "CURENT" && "autocitire_table_option-selected"
            }`}
          >
            <p>Energie electrica</p>
          </div>
          <div
            onClick={() => {
              setSelectedOption("GAZ");
              setFilteredAutocitireTableData(
                autocitireTableData.filter(
                  (item) => item.utility_type === "gas",
                ),
              );
            }}
            className={`autocitire_table_option ${
              selectedOption === "GAZ" && "autocitire_table_option-selected"
            }`}
          >
            <p>Gaz</p>
          </div>
        </div>
        <div className="autocitire-table-line" />
      </div>
      <div className="autocitire-table-autocitiri-export">
        <p>{autocitiriNumber} autocitiri</p>
        <div>
          {helpers.isAvailableSelfReading() && (
            <SubmitButton
              disabled={!isSelfReadingsEnabled}
              onClick={() => setShowAdaugaAutocitire(true)}
              classNames={
                "buton-bg-alb-green autocitire-table-export-button autocitire-adauga-autocitire-button"
              }
              text={"Adauga autocitire"}
            />
          )}
          <SubmitButton
            classNames={"buton-bg-alb-green autocitire-table-export-button"}
            onClick={exportXLSX}
            data-pr-tooltip="CSV"
            text={"Export"}
          />
        </div>
      </div>
    </div>
  );
};

export const tableAutocitireHelper = {
  header,
  tableTip,
  tableData,
  tableLocDeConsum,
  tableConsumTotal,
  tableAutocitiriTrimise,
  tableSerieContor,
  tableStatus,
};
