import React, { useContext, useEffect, useState } from "react";
import Layout from "./Layout";
import { AppContext } from "App";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import SubmitButton from "components/SubmitButton";
import { apiLocuriDeConsum } from "../api/locuriDeConsum.service";
import ComponentLoading from "components/Loaders/ComponentLoading";
import ModalSemnareModificareConventie from "components/LocDeConsum/ModalSemnareModificareConventie";
const MONTHS = [
  { value: "January", label: "Ianuarie" },
  { value: "February", label: "Februarie" },
  { value: "March", label: "Martie" },
  { value: "April", label: "Aprilie" },
  { value: "May", label: "Mai" },
  { value: "June", label: "Iunie" },
  { value: "July", label: "Iulie" },
  { value: "August", label: "August" },
  { value: "September", label: "Septembrie" },
  { value: "October", label: "Octombrie" },
  { value: "November", label: "Noiembrie" },
  { value: "December", label: "Decembrie" },
];

function Switch({ isSwitchOn, handleSwitchClick }) {
  return (
    <div className="switch-container">
      <p className={!isSwitchOn ? "switch--on-text" : ""}>kWh</p>
      <div
        onClick={handleSwitchClick}
        className={`switch ${isSwitchOn && "switch--on"}`}
        aria-label="Switch between kWh and MWh"
      >
        <div className="switch__inner" />
      </div>
      <p className={isSwitchOn ? "switch--on-text" : ""}>MWh</p>
    </div>
  );
}
const valueForConventieInput = (consumption, month, typeOfOperation) => {
  const operations = {
    "/": (value) => value / 1000,
    "*": (value) => value * 1000,
  };
  const operationFunction = operations[typeOfOperation];

  let value;

  value = consumption[month];
  const result = operationFunction(value);

  return String(result);
};
const ConventieConsum = (props) => {
  const { userLayoutInfo } = useContext(AppContext);
  const location = useLocation();

  const [isFetching, setIsFetching] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editModeBlocked, setEditModeBlocked] = useState(true);
  const [consumption, setConsumption] = useState(null);
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const [displaySignature, setDisplaySignature] = useState(false);
  const [signature, setSignature] = useState(null);
  // Accessing the parameters passed through the navigation method
  const locDeConsumInfoData = location.state?.locDeConsumInfoData;

  const isPF = userLayoutInfo.data.account_type === "pf";
  useEffect(() => {
    const fetchData = async () => {
      if (typeof userLayoutInfo.data.account_type === "undefined") {
        return;
      }
      try {
        setIsFetching(true);
        const response = await apiLocuriDeConsum.getConventieConsum(
          locDeConsumInfoData?.metering_point_id,
        );
        const responseData = response?.response?.data;
        if (response.isSuccess && responseData) {
          setIsSwitchOn(isPF ? false : !userLayoutInfo?.data?.is_association);
          setEditModeBlocked(Boolean(!responseData?.is_editable));
          if (responseData?.is_editable === false) {
            toast.warning(
              "O solicitare de modificare a convenției se află în procesare. ",
            );
          }
          setConsumption(proccessReceivedConsumption(responseData));
        }
      } finally {
        setIsFetching(false);
      }
    };

    fetchData();
  }, [
    userLayoutInfo?.data?.account_type,
    locDeConsumInfoData?.metering_point_id,
  ]);

  const proccessReceivedConsumption = (responseData) => {
    // debugger;
    const userConsumption = {};
    for (const month of MONTHS) {
      userConsumption[month.value] =
        responseData[
          `${month.value.toLocaleLowerCase()}${isPF ? "kwh" : userLayoutInfo?.data?.is_association ? "kwh" : "mwh"}`
        ];
    }
    return userConsumption;
  };
  // const navigation = useNavigate();
  const handleSwitch = () => {
    const valueOfSwitch = isSwitchOn;
    setIsSwitchOn(!valueOfSwitch);
    const statusOfSwitch = !valueOfSwitch;
    const newConsumption = calculateConsumption(statusOfSwitch);
    setConsumption(newConsumption);
  };

  const calculateConsumption = (statusOfSwitch) => {
    const newConsumption = {};
    for (const month of MONTHS) {
      newConsumption[month.value] = valueForConventieInput(
        consumption,
        month.value,
        statusOfSwitch ? "/" : "*",
      );
    }
    return newConsumption;
  };

  const openModal = () => {
    setDisplaySignature(true);
  };

  const requestEditConventieConsum = async (signatureValue = null) => {
    const consumptionData = {};
    // pf - mwh - *
    // pj - kwh - don't do anything
    // pj - mwh - don't do anything
    // pj - kwh - /
    for (const month of MONTHS) {
      const unitMeasure = isSwitchOn ? "mwh" : "kwh";
      const month_key =
        `${month.value}${isPF || userLayoutInfo?.data?.is_association ? "kwh" : "mwh"}`.toLocaleLowerCase();
      if (
        ((isPF || userLayoutInfo?.data?.is_association) &&
          unitMeasure === "kwh") ||
        (!isPF &&
          !userLayoutInfo?.data?.is_association &&
          unitMeasure === "mwh")
      ) {
        consumptionData[`${month_key.toLocaleLowerCase()}`] =
          consumption[month.value].toString();
      } else {
        consumptionData[month_key] = valueForConventieInput(
          consumption,
          month.value,
          isPF || userLayoutInfo?.data?.is_association ? "*" : "/",
        );
      }
    }

    if (!signatureValue) {
      toast.error("Va rugam sa semnati pentru a trimite solicitarea.");
      return;
    }
    setIsFetching(true);
    try {
      const response = await apiLocuriDeConsum.editConventieConsum({
        metering_point_id: locDeConsumInfoData.metering_point_id,
        contract_parent_id: locDeConsumInfoData.contract_id,
        consumption: consumptionData,
        utility_type: locDeConsumInfoData.utility_type,
        signature: signatureValue.substring(22),
      });
      if (response.isSuccess) {
        toast.success("Solicitarea a fost transmisa cu succes.");
        setEditModeBlocked(true);
        setEditMode(false);
      } else {
        toast.error(
          response?.error ||
            "Solicitarea nu a putut fi trimisa. Va rugam incercati din nou mai tarziu.",
        );
      }
      setIsFetching(false);
    } catch (error) {
      console.error("Error editing data:", error);
      toast.error(
        "Solicitarea nu a putut fi trimisa. Va rugam incercati din nou mai tarziu.",
      );
      setIsFetching(false);
    }
  };

  const handleInputChange = (text, index) => {
    const newText = text.replace(/[^0-9.]/g, ""); // Allow only numbers and decimals
    if (newText === "" || parseFloat(newText) >= 0) {
      setConsumption((prev) => {
        return {
          ...prev,
          [MONTHS[index].value]: newText === "" ? 0 : parseFloat(newText),
        };
      });
    }
  };

  if (!consumption || isFetching) {
    return (
      <Layout>
        {isFetching ? (
          <ComponentLoading />
        ) : (
          <div className="conventie-consum-container">
            <h4>Conventie Consum</h4>
            <p>
              Nu am gasit conventia pentru acest loc de consum. Va rugam
              reveniti.
            </p>
          </div>
        )}
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="conventie-consum-container">
        <div className="conventie-consum-header">
          <h4>Conventie Consum</h4>

          {editMode ? (
            <div className="conventie-consum-edit-buttons-group">
              <SubmitButton
                classNames="conventie-consum-cancel-button"
                text="Renunțǎ"
                onClick={() => setEditMode(false)}
              />
              <SubmitButton
                classNames="conventie-consum-submit-button"
                onClick={openModal}
                text="Semneazǎ"
              />
            </div>
          ) : (
            <div className="conventie-consum-edit-buttons-group">
              <SubmitButton
                classNames="conventie-consum-edit-button"
                text="Modificǎ"
                disabled={editModeBlocked}
                onClick={() => setEditMode(true)}
              />
            </div>
          )}
        </div>
        <div className="conventie-consum-switch-container">
          <div>
            <p>
              {locDeConsumInfoData.utility_type === "Electricitate"
                ? "Energie electrică"
                : "Gaze naturale"}
            </p>
            <p>
              {locDeConsumInfoData.utility_type === "Electricitate"
                ? "1000 kWh = 1 MWh"
                : "1 kWh = 0.09mc, 1 mc = 10.58kWh"}
            </p>
          </div>
          <div>
            <p>Cantitate estimată: </p>
            <Switch isSwitchOn={isSwitchOn} handleSwitchClick={handleSwitch} />
          </div>
        </div>

        <div className="conventie-consum-table">
          {MONTHS.map((item, index) => (
            <div key={index} className="conventie-consum-row">
              <div className="conventie-consum-row-label">
                <p className="conventie-consum-row-text">{item.label}</p>
              </div>
              <div>
                <input
                  className="input-consumption"
                  placeholder="-"
                  type="number"
                  disabled={!editMode}
                  value={consumption[item.value]}
                  onChange={(e) => handleInputChange(e.target.value, index)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {displaySignature && (
        <ModalSemnareModificareConventie
          setVisible={setDisplaySignature}
          signatureValue={signature}
          visible={displaySignature}
          setSignature={setSignature}
          onSaveSignature={requestEditConventieConsum}
        />
      )}
    </Layout>
  );
};
export default ConventieConsum;
