import React, { useEffect, useState } from "react";
import { apiLocuriDeConsum } from "api/locuriDeConsum.service";
import ComponentLoading from "components/Loaders/ComponentLoading";
import SubmitButton from "components/SubmitButton";
import GAZ from "assets/images/common/gaz.png";
import CURENT from "assets/images/common/curent.png";
import { useNavigate } from "react-router-dom";

export default function LocDeConsumInfoContent({ locDeConsumShowing }) {
  const [locDeConsumInfoData, setLocDeConsumInfoData] = useState([]);
  const [isDataFetching, setIsDataFetching] = useState(false);
  const navigation = useNavigate();
  useEffect(() => {
    const getLocDeConsumInfoData = async () => {
      setIsDataFetching(true);
      const response = await apiLocuriDeConsum.getLocDeConsumInfo(
        locDeConsumShowing.id,
      );
      if (response.isSuccess) {
        setIsDataFetching(false);
        setLocDeConsumInfoData(response.response.data[0]);
      }
    };

    getLocDeConsumInfoData();
  }, []);

  const codFurnizare =
    locDeConsumInfoData.utility_type === "Electricitate"
      ? `Cod POD: ${locDeConsumInfoData.pod}`
      : `Cod CLC: ${locDeConsumInfoData.pod}`;

  return (
    <div className="locDeConsumInfoContent">
      <div className="locDeConsumInfo-adresa-servicii-container">
        <div className="locDeConsumInfo-adresa">
          {isDataFetching ? (
            <ComponentLoading />
          ) : (
            <>
              <h2>Adresa</h2>
              <p>{locDeConsumInfoData.address}</p>
            </>
          )}
        </div>
        <div className="locDeConsumInfo-serviciiActive">
          {isDataFetching ? (
            <ComponentLoading />
          ) : (
            <>
              <div className="locDeConsumInfo-serviciiActive-text">
                <h2>Servicii active</h2>
                <p>{locDeConsumInfoData.utility_type}</p>
                <p>{codFurnizare}</p>
              </div>
              <div
                className={`gaz-icon-locDeConsum-container ${
                  locDeConsumInfoData.utility_type === "Electricitate" &&
                  "curent-background"
                }`}
              >
                <img
                  src={
                    locDeConsumInfoData.utility_type === "Electricitate"
                      ? CURENT
                      : GAZ
                  }
                  alt="gaz_curent-icon"
                />
              </div>
            </>
          )}
        </div>
        <div className="locDeConsumInfo-conventieConsum">
          {isDataFetching ? (
            <ComponentLoading />
          ) : (
            <div className="locDeConsumInfoContent-conventieConsum-wrapper">
              <div className="locDeConsumInfo-conventieConsum-text">
                <h2>Convenție consum</h2>
              </div>
              <SubmitButton
                text="Modifica convenție consum"
                onClick={() =>
                  navigation("/conventie-consum", {
                    state: { locDeConsumInfoData },
                  })
                }
                classNames={
                  "buton-bg-alb-green locDeConsumInfoContent-conventieConsum-button"
                }
              />
            </div>
          )}
        </div>
      </div>
      {locDeConsumInfoData.utility_type !== "Electricitate" && (
        <>
          <div className="locDeConsumInfo-adresa-servicii-container">
            <div className="locDeConsumInfo-verificari">
              {isDataFetching ? (
                <ComponentLoading />
              ) : (
                <>
                  <div className="locDeConsumInfo-serviciiActive-text">
                    <h2>Verificari</h2>
                    <p>Ultima verificare: {locDeConsumInfoData.last_check}</p>
                    <p>
                      Urmatoarea verificare: {locDeConsumInfoData.next_check}
                    </p>
                  </div>
                  <div
                    className={`gaz-icon-locDeConsum-container ${
                      locDeConsumInfoData.utility_type === "Electricitate" &&
                      "curent-background"
                    }`}
                  >
                    <img
                      src={
                        locDeConsumInfoData.utility_type === "Electricitate"
                          ? CURENT
                          : GAZ
                      }
                      alt="gaz_curent-icon"
                    />
                  </div>
                </>
              )}
            </div>
            <div className="locDeConsumInfo-revizii">
              {isDataFetching ? (
                <ComponentLoading />
              ) : (
                <>
                  <div className="locDeConsumInfo-serviciiActive-text">
                    <h2>Revizii</h2>
                    <p>Ultima revizie: {locDeConsumInfoData.last_revision}</p>
                    <p>
                      Urmatoarea revizie: {locDeConsumInfoData.next_revision}
                    </p>
                  </div>
                  <div
                    className={`gaz-icon-locDeConsum-container ${
                      locDeConsumInfoData.utility_type === "Electricitate" &&
                      "curent-background"
                    }`}
                  >
                    <img
                      src={
                        locDeConsumInfoData.utility_type === "Electricitate"
                          ? CURENT
                          : GAZ
                      }
                      alt="gaz_curent-icon"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
