import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { tableLocuriDeConsumHelper } from "helpers/tableHelpers/tableLocuriDeConsumHelper";
import { apiLocuriDeConsum } from "api/locuriDeConsum.service";
import ComponentLoading from "components/Loaders/ComponentLoading";
import { createXLSX, saveAsExcelFile } from "../../helpers/xlsx";
import { calculatePaginationArray } from "../../helpers/helpers";
export default function DataTableLocDeConsum({ setLocDeConsumShowing }) {
  const [locDeConsumTableData, setLocDeConsumTableData] = useState([]);
  const [filteredLocDeConsumTableData, setFilteredLocDeConsumTableData] =
    useState([]);
  const [tipFurnizare, setTipFurnizare] = useState("TOATE");
  const [isDataFetching, setIsDataFetching] = useState(false);

  const dt = useRef(null);

  const getLocuriDeConsumCallback = async () => {
    setIsDataFetching(true);
    try {
      const response = await apiLocuriDeConsum.getLocuriDeConsum();
      console.log("🚀 ~ getLocuriDeConsumCallback ~ response:", response);
      if (response.isSuccess) {
        setLocDeConsumTableData(response.response.data);
        setFilteredLocDeConsumTableData(response.response.data);
      }
    } catch (r) {
      console.log("🚀 ~ getLocuriDeConsumCallback ~ r", r);
    }

    setIsDataFetching(false);
  };

  const exportXLSX = () => {
    const COLUMNS_EXPORT_EXCEL = {
      ContractType: "Furnizare",
      Location: "Adresa de furnizare",
      ClientType: "Tip contract",
    };
    const dataForXLSX = filteredLocDeConsumTableData.map((item) => ({
      [COLUMNS_EXPORT_EXCEL.ContractType]: item?.utility_type || "",
      [COLUMNS_EXPORT_EXCEL.Location]: item?.address || "",
      [COLUMNS_EXPORT_EXCEL.ClientType]: item?.contract_type || "",
    }));
    const title = "Locuri de consum - NOVA";
    const tableHead = [
      COLUMNS_EXPORT_EXCEL.ContractType,
      COLUMNS_EXPORT_EXCEL.Location,
      COLUMNS_EXPORT_EXCEL.ClientType,
    ];
    const excelBuffer = createXLSX(title, tableHead, dataForXLSX, 1);
    saveAsExcelFile(excelBuffer, title);
  };
  const rowsPerPageOptions = calculatePaginationArray(
    filteredLocDeConsumTableData,
    4,
  );
  useEffect(() => {
    getLocuriDeConsumCallback();
  }, []);

  return (
    <>
      {isDataFetching ? (
        <ComponentLoading />
      ) : (
        <DataTable
          ref={dt}
          exportFilename="Locuri de consum"
          className="locuriDeConsum-table"
          value={filteredLocDeConsumTableData}
          header={tableLocuriDeConsumHelper.header(
            tipFurnizare,
            setTipFurnizare,
            locDeConsumTableData,
            setFilteredLocDeConsumTableData,
            exportXLSX,
          )}
          paginator
          rows={4}
          rowsPerPageOptions={rowsPerPageOptions}
        >
          <Column
            field="utility_type"
            body={tableLocuriDeConsumHelper.tableTip}
          />
          <Column
            field="address_summary"
            body={tableLocuriDeConsumHelper.tableLocatie}
          />
          <Column
            field="address"
            body={tableLocuriDeConsumHelper.tableLocatieCompleta}
          />
          <Column
            field="button"
            body={(rowData) =>
              tableLocuriDeConsumHelper.tableButton(
                rowData,
                setLocDeConsumShowing,
              )
            }
          />
        </DataTable>
      )}
    </>
  );
}
