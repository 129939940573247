import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { tableFacturileMeleHelper } from "helpers/tableHelpers/tableFacturileMeleHelper";
import ComponentLoading from "components/Loaders/ComponentLoading";

import ModalDetaliiFactura from "components/FacturiSiPlati/ModalDetaliiFactura";
import { apiLocuriDeConsum } from "api/locuriDeConsum.service";
import ACHITAT from "assets/images/common/checked.png";
import { Toast } from "primereact/toast";
import { SplitButton } from "primereact/splitbutton";
import ModalPlataPartiala from "components/FacturiSiPlati/ModalPlataPartiala";

import {
  createXLSX,
  saveAsExcelFile,
  getFormattedDateInterval,
} from "helpers/xlsx";
import {
  calculatePaginationArray,
  getSuccessUrlPayment,
} from "helpers/helpers";
import { requestJson, RequestMethod } from "helpers/httpHelper";

export default function DataTableFacturileMele({
  dashboard = false,
  facturileMeleTableData = [],
  isDataFetching = false,
}) {
  const [filteredFacturileMeleTableData, setFilteredFacturileMeleTableData] =
    useState(facturileMeleTableData || []);
  const [locuriConsum, setLocuriConsum] = useState([]);

  const [selectedTipFurnizare, setSelectedTipFurnizare] = useState("TOATE");
  const [selectedLocDeConsum, setSelectedLocDeConsum] = useState("TOATE");
  const [selectedStatus, setSelectedStatus] = useState("TOATE");
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isModalDetaliiFactura, setIsModalDetaliiFactura] = useState(false);
  const [isModalPlatesteFactura, setModalPlatesteFactura] = useState(false);
  const [buttonsPressed, setButtonsPressed] = useState({});
  const dt = useRef(null);
  const toast = useRef(null);

  const getLocuriConsumCallback = async () => {
    const response = await apiLocuriDeConsum.getLocuriDeConsum();
    if (response.isSuccess) {
      setLocuriConsum(response.response.data);
    }
  };

  const exportXLSX = () => {
    const statusMap = {
      paid: "Achitat",
      unpaid: "Neachitat",
      storno: "Stornata",
    };
    const COLUMNS_EXPORT_EXCEL = {
      Series: "Serie factura",
      MeasurePoint: "Loc de consum",
      DueDate: "Scadenta la",
      Value: "Valoare",
      Status: "Status",
      Type: "Tip furnizare",
    };

    const dataForXLSX = filteredFacturileMeleTableData.map((item) => ({
      [COLUMNS_EXPORT_EXCEL.Type]:
        item.utility_type === "energy" ? "Electricitate" : "Gaz",
      [COLUMNS_EXPORT_EXCEL.Series]: item.invoice_id,
      [COLUMNS_EXPORT_EXCEL.MeasurePoint]:
        item.invoice_metering_point_short_address,
      [COLUMNS_EXPORT_EXCEL.DueDate]: item.invoice_due_date,
      [COLUMNS_EXPORT_EXCEL.Value]: item.amount,
      [COLUMNS_EXPORT_EXCEL.Status]: statusMap?.[item.status] || "",
    }));
    // Create a workbook

    // Add a title cell at the top
    const datesInterval = getFormattedDateInterval(
      dataForXLSX,
      COLUMNS_EXPORT_EXCEL.DueDate,
    );
    const title = `Facturi Nova (${datesInterval})`;

    // Add a headline (header) row after the title
    const tableHead = Object.values(COLUMNS_EXPORT_EXCEL);
    const excelBuffer = createXLSX(title, tableHead, dataForXLSX, 2);
    saveAsExcelFile(excelBuffer, title);
  };

  const handleChangeFilter = (
    value,
    filterType,
    facturileMeleTableData,
    setFilteredFacturileMeleTableData,
  ) => {
    let filteredData;

    if (filterType === "status") {
      setSelectedStatus(value);
      setSelectedTipFurnizare("TOATE");
      setSelectedLocDeConsum("TOATE"); // Resetează starea pentru status
    } else if (filterType === "utility_type") {
      setSelectedTipFurnizare(value);
      setSelectedLocDeConsum("TOATE"); // Resetează starea pentru status
      setSelectedStatus("TOATE"); // Resetează starea pentru status
    }

    if (value !== "TOATE") {
      filteredData = facturileMeleTableData.filter(
        (item) => item[filterType] === value,
      );
    } else {
      filteredData = [...facturileMeleTableData];
    }
    setFilteredFacturileMeleTableData(filteredData);
  };

  const handleChangeLocDeConsum = (
    value,
    facturileMeleTableData,
    setFilteredFacturileMeleTableData,
  ) => {
    setSelectedLocDeConsum(value); // Actualizează starea cu noua selecție
    setSelectedStatus(null); // Resetează starea pentru status
    setSelectedTipFurnizare("TOATE"); // Resetează starea pentru tipul de furnizare

    if (value === "TOATE") {
      // Dacă "Toate" este selectat, afișăm toate facturile
      setFilteredFacturileMeleTableData(facturileMeleTableData);
    } else {
      // Filtrăm facturile pe baza selecției
      const filteredData = facturileMeleTableData.filter((item) => {
        // Verifică dacă array-ul invoice_metering_point_ids conține valoarea selectată
        return item.invoice_metering_point_ids.includes(value);
      });
      setFilteredFacturileMeleTableData(filteredData);
    }
  };

  const handlePlatesteToataFactura = async (event, data) => {
    event.preventDefault();
    const response = await requestJson(
      `invoices/in-progress`,
      RequestMethod.POST,
      {
        useAccessToken: true,
        data: {
          invoice_id: data.invoice_id,
        },
      },
    );
    const form = event.target.closest("form");
    form.submit();
  };

  const handleDetalii = (event, data) => {
    setSelectedInvoice(data);
    setIsModalDetaliiFactura(true);
  };

  const tableSerie = (rowData) => {
    return (
      <div
        className="facturileMele-table-serie"
        style={{ cursor: "pointer" }}
        onClick={(event) => {
          handleDetalii(event, rowData);
        }}
      >
        <p>{rowData.invoice_id}</p>
        <p className="facturileMele-table-locDeConsum-mobile">
          {/*<p>{rowData.invoice_id}</p>*/}
        </p>
      </div>
    );
  };

  const tableLocDeConsum = (rowData) => {
    return (
      <>
        <div
          className="facturileMele-table-locDeConsum"
          style={{ cursor: "pointer" }}
          onClick={(event) => {
            handleDetalii(event, rowData);
          }}
        >
          {rowData.invoice_metering_points.length > 1 ? (
            <p>Vezi toate locurile</p>
          ) : (
            <p>{rowData.invoice_metering_point_long_address}</p>
          )}
        </div>
        {
          <div className="facturileMele-table-serie-mobile">
            {rowData.invoice_metering_points.length > 1 ? (
              <p>Vezi toate informatiile</p>
            ) : (
              <p>{rowData.invoice_metering_point_long_address}</p>
            )}
          </div>
        }
      </>
    );
  };

  const tableStatus = (rowData) => {
    const items = [
      {
        label: "Plateste partial",
        icon: "pi pi-refresh",
        command: () => {
          setSelectedInvoice(rowData);
          setModalPlatesteFactura(true);
        },
      },
    ];

    const successUrl = getSuccessUrlPayment(rowData.invoice_id);
    const renderPayButton = () => {
      return rowData.invoice_metering_points.length === 1 ? (
        <>
          <Toast ref={toast}></Toast>
          <SplitButton
            type={"submit"}
            label="Plateste"
            icon="pi pi-plus"
            onClick={(event) => {
              handlePlatesteToataFactura(event, rowData);
            }}
            model={items}
            // className={"custom-submitButton dashboard_table_status_buton"}
          />
        </>
      ) : (
        <button
          type="submit"
          onClick={(event) => {
            handlePlatesteToataFactura(event, rowData);
          }}
          className="custom-submitButton dashboard_table_status_buton"
        >
          Plateste
        </button>
      );
    };
    const statusMap = {
      paid: (
        <div className="facturileMele_table_status_achitat">
          <img src={ACHITAT} alt="icon_achitat" />
          <p>Achitat</p>
        </div>
      ),
      storno: (
        <div className="facturileMele_table_status_pending">
          <p>Storno</p>
        </div>
      ),
      unpaid: (
        <>
          <form
            action="https://secure.euplatesc.ro/tdsprocess/tranzactd.php"
            method="POST"
            name="gateway"
            target="_self"
          >
            <input type="hidden" name="amount" value={rowData.amount} />
            <input type="hidden" name="curr" value={rowData.curr} />
            <input type="hidden" name="invoice_id" value={rowData.invoice_id} />
            <input type="hidden" name="order_desc" value={rowData.order_desc} />
            <input type="hidden" name="merch_id" value={rowData?.merch_id} />
            <input type="hidden" name="timestamp" value={rowData?.timestamp} />
            <input type="hidden" name="nonce" value={rowData?.nonce} />
            <input type="hidden" name="c2p_id" value={rowData?.c2p_id} />
            <input type="hidden" name="fp_hash" value={rowData?.fp_hash} />
            <input type="hidden" name="email" value={rowData?.email} />
            <input type="hidden" name="fname" value={rowData?.fname} />
            <input
              type="hidden"
              name="ExtraData[successurl]"
              value={successUrl}
            />
            <input
              type="hidden"
              name="ExtraData[backtosite]"
              value={rowData["ExtraData[backtosite]"]}
            />
            {renderPayButton()}
          </form>
        </>
      ),
      default: (
        <div className="facturileMele_table_status_pending">
          <p>Eroare</p>
        </div>
      ),
      "payment-in-progress": (
        <div className="facturileMele_table_status_pending">
          <p>In procesare</p>
        </div>
      ),
    };

    return (
      <div className="facturileMele_table_status_div">
        {statusMap[rowData.status] || statusMap["default"]}
      </div>
    );
  };

  useEffect(() => {
    getLocuriConsumCallback();
  }, []);

  useEffect(() => {
    if (facturileMeleTableData.length > 0) {
      setFilteredFacturileMeleTableData(facturileMeleTableData);
    }
  }, [isDataFetching, facturileMeleTableData]);

  const rowsPerPageOptions = calculatePaginationArray(
    filteredFacturileMeleTableData,
  );
  return (
    <>
      {isDataFetching && dashboard === false ? (
        <ComponentLoading parrentClassName={"my-account-loading-div"} />
      ) : (
        <DataTable
          loading={isDataFetching}
          ref={dt}
          exportFilename="Facturi"
          className={
            dashboard === true ? "dashboard-table" : "facturileMele-table"
          }
          // className="facturileMele-table"
          value={filteredFacturileMeleTableData}
          header={tableFacturileMeleHelper.header(
            locuriConsum,
            filteredFacturileMeleTableData,
            setFilteredFacturileMeleTableData,
            exportXLSX,
            facturileMeleTableData,
            handleChangeFilter,
            handleChangeLocDeConsum,
            selectedTipFurnizare,
            selectedLocDeConsum,
            selectedStatus,
          )}
          paginator={true}
          rowsPerPageOptions={rowsPerPageOptions}
          rows={5}
          removableSort
          sortField="invoice_due_date"
          sortOrder={-1} // 1 asc, -1 desc
        >
          <Column
            sortable
            field="utility_type"
            header="Tip"
            body={tableFacturileMeleHelper.tableTipFurnizare}
          />
          <Column
            sortable
            field="invoice_id"
            header="Seria factura"
            body={tableSerie}
          />
          <Column
            sortable
            field="invoice_metering_point_long_address"
            header="Loc de consum"
            body={tableLocDeConsum}
          />
          <Column
            sortable
            field="invoice_due_date"
            header="Scadent"
            body={tableFacturileMeleHelper.tableScadent}
          />
          <Column
            sortable
            field="amount"
            header="Valoare"
            body={tableFacturileMeleHelper.tableValoare}
          />
          <Column sortable field="status" header="Status" body={tableStatus} />
        </DataTable>
      )}
      {isModalDetaliiFactura && (
        <ModalDetaliiFactura
          isDialogVisible={isModalDetaliiFactura}
          setIsDialogVisible={setIsModalDetaliiFactura}
          selectedInvoice={selectedInvoice}
        />
      )}

      {isModalPlatesteFactura && (
        <ModalPlataPartiala
          isDialogVisible={isModalPlatesteFactura}
          setIsDialogVisible={setModalPlatesteFactura}
          selectedInvoice={selectedInvoice}
        />
      )}
    </>
  );
}
