import { requestJson, RequestMethod } from "helpers/httpHelper";

const myAccountDetails = async () => {
  const response = await requestJson("account", RequestMethod.GET, {
    useAccessToken: true,
  });
  return response;
};

const myContracts = async () => {
  const response = await requestJson("contract", RequestMethod.GET, {
    useAccessToken: true,
  });
  return response;
};

const myContacts = async () => {
  const response = await requestJson("account/contacts", RequestMethod.GET, {
    useAccessToken: true,
  });
  return response;
};

const getAllAccountDetails = async () => {
  const response = await requestJson("account/details", RequestMethod.GET, {
    useAccessToken: true,
  });
  return response;
};

const patchAllAccountDetails = async (data) => {
  const response = await requestJson("account/details", RequestMethod.PATCH, {
    useAccessToken: true,
    headers: [
      { name: "Content-Type", value: "application/x-www-form-urlencoded" },
    ],
    data: data,
  });
  return response;
};

const createNewContact = async (data) => {
  const response = await requestJson("account/contacts", RequestMethod.POST, {
    useAccessToken: true,
    headers: [
      { name: "Content-Type", value: "application/x-www-form-urlencoded" },
    ],
    data: data,
  });
  return response;
};

const editContact = async (data) => {
  const response = await requestJson("account/contacts", RequestMethod.PATCH, {
    useAccessToken: true,
    headers: [
      { name: "Content-Type", value: "application/x-www-form-urlencoded" },
    ],
    data: data,
  });
  return response;
};

const deleteContact = async (data) => {
  const response = await requestJson("account/contacts", RequestMethod.DELETE, {
    useAccessToken: true,
    data: data,
  });

  return response;
};

export const apiContulMeu = {
  myAccountDetails,
  myContracts,
  myContacts,
  getAllAccountDetails,
  patchAllAccountDetails,
  createNewContact,
  editContact,
  deleteContact,
};
