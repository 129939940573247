import ErrorIcon from "assets/images/common/errIcon.png";

const getEPDivClass = (location, route) => {
  if (location.pathname === route)
    return "header-edit-profile-div header-edit-profile-div-selected";

  return "header-edit-profile-div";
};

const getHeaderTitle = (location) => {
  switch (location.pathname) {
    case "/dashboard":
      return "Acasa";
    case "/facturi-si-plati":
      return "Facturi si plati";
    case "/contul-meu":
      return "Contul meu";
    case "/autocitire":
      return "Autocitire";
    case "/locuri-de-consum":
      return "Locuri de consum";
    case "/rapoarte":
      return "Rapoarte";
    case "/suport":
      return "Suport";
    case "/conventie-consum":
      return "Conventie de consum";
    default:
      return "Acasa";
  }
};

const getLeftMenuClass = (location, route) => {
  if (location.pathname === route) return "menu-row menu-row-selected";

  return "menu-row";
};

const getFooterDivClass = (location, route) => {
  if (location.pathname === route)
    return "footer-icon-container footer-icon-container-selected";

  return "footer-icon-container";
};

const getAutocitireCardClass = (type) => {
  if (type === "CURENT")
    return "autocitire-card-image-container autocitire-card-image-container-curent";
  return "autocitire-card-image-container";
};

const normalSelectStyle = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "1px solid #a2a2a2",
    borderRadius: "7.2px",
    minHeight: "49px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? "#f5f5f5"
          : isFocused
            ? "#f5f5f5"
            : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
          ? "#000"
          : isFocused
            ? "#000"
            : "#000",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
  singleValue: (styles, { data }) => ({ ...styles, color: "#000" }),
};

const errorSelectStyle = {
  control: (styles) => ({
    ...styles,
    // backgroundImage: `url(${ErrorIcon})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "87% 50%",
    backgroundColor: "white",
    border: "1px solid #ff6363!important",
    minHeight: "39px",
    borderRadius: "7.2px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? "#f5f5f5"
          : isFocused
            ? "#f5f5f5"
            : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
          ? "#000"
          : isFocused
            ? "#000"
            : "#000",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
  singleValue: (styles, { data }) => ({ ...styles, color: "#000" }),
};

export const styleHelpers = {
  getEPDivClass,
  getHeaderTitle,
  getLeftMenuClass,
  getFooterDivClass,
  getAutocitireCardClass,
  normalSelectStyle,
  errorSelectStyle,
};
