import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import { AppContext } from "App";
import { AuthService } from "api/auth.service";
import { helpers } from "helpers/helpers";
import { useFormik } from "formik";
import { schimbaParolaSchema } from "schemas/yupSchema";
import { toast } from "react-toastify";
import TextInput from "components/TextInput";
import SubmitButton from "components/SubmitButton";
import { ReactComponent as ICON_CLOSE } from "assets/images/common/x_button.svg";
import { Password } from "primereact/password";
import { Divider } from "primereact/divider";

export default function ModalSchimbaParola({ setShowModficaParola }) {
  const { setIsScreenLoading } = useContext(AppContext);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: isFormSubmitted,
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: schimbaParolaSchema,
    onSubmit: async (values) => {
      helpers.scrollToTop();
      setIsScreenLoading(true);

      const response = await AuthService.changePassword(
        values.currentPassword,
        values.newPassword,
        values.confirmNewPassword,
      );
      if (response.isSuccess) {
        toast.success(response.response.message);
        setShowModficaParola(false);
        setIsScreenLoading(false);
      } else {
        setIsScreenLoading(false);
        toast.error("A aparut o eroare la schimbarea parolei!");
      }
    },
  });

  const header = <div className="font-bold mb-3">Seteaza o parola</div>;
  const footer = (
    <>
      <Divider />
      <p className="mt-2">Reguli</p>
      <ul className="pl-2 ml-2 mt-0 line-height-3">
        <li>Cel putin o litera mica</li>
        <li>Cel putin o litera mare</li>
        <li>Cel putin un numar</li>
        <li>Cel putin un caracter special</li>
      </ul>
    </>
  );

  return (
    <>
      {ReactDOM.createPortal(
        <div className="modifica-date-container">
          <div className="modifica-date-content">
            <div className="modifica-date-header">
              <h2>Modifică Parola</h2>
              <ICON_CLOSE
                style={{ cursor: "pointer" }}
                onClick={() => setShowModficaParola(false)}
              />
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className="modifica-date-textInput-row">
                <h2>Parola actuala</h2>
                <TextInput
                  id={"currentPassword"}
                  name={"currentPassword"}
                  type={"password"}
                  placeholder={"Parola actuala"}
                  classNames={"modifica-date-modal-textInput"}
                  value={formik.values.currentPassword}
                  error={formik.errors.currentPassword}
                  touched={formik.touched.currentPassword}
                  setFieldValue={formik.setFieldValue}
                />
              </div>
              {/*<div className="modifica-date-textInput-row">*/}
              {/*    <h2>Parola actuala</h2>*/}
              {/*    <Password*/}
              {/*        id={"currentPassword"}*/}
              {/*        name={"currentPassword"}*/}
              {/*        placeholder={"Parola actuala"}*/}
              {/*        toggleMask*/}
              {/*        className="modifica-date-modal-textInput"*/}
              {/*        value={formik.values.currentPassword}*/}
              {/*        error={formik.errors.currentPassword}*/}
              {/*        touched={formik.touched.currentPassword}*/}
              {/*        setFieldValue={formik.setFieldValue}*/}
              {/*    />*/}
              {/*                  {formik.touched.currentPassword && formik.errors.currentPassword ? (*/}
              {/*                      <small className="p-error">{formik.errors.currentPassword}</small>*/}
              {/*                  ) : null}*/}
              {/*  </div>*/}
              <div className="modifica-date-textInput-row">
                {/*<h2>Parola nouă</h2>*/}
                {/*<TextInput*/}
                {/*  id={"newPassword"}*/}
                {/*  name={"newPassword"}*/}
                {/*  type={"text"}*/}
                {/*  placeholder={"Parola nouă"}*/}
                {/*  classNames={"modifica-date-modal-textInput"}*/}
                {/*  value={formik.values.newPassword}*/}
                {/*  error={formik.errors.newPassword}*/}
                {/*  touched={formik.touched.newPassword}*/}
                {/*  setFieldValue={formik.setFieldValue}*/}
                {/*/>*/}
                <div className="modifica-date-textInput-row">
                  <h2>Parola nouă</h2>
                  <Password
                    id="newPassword"
                    name="newPassword"
                    placeholder="Parola nouă"
                    toggleMask
                    className="modifica-date-modal-textInput w-100"
                    inputStyle={{ width: "100%" }}
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    header={header}
                    footer={footer}
                  />
                  {formik.touched.newPassword && formik.errors.newPassword ? (
                    <small className="p-error">
                      {formik.errors.newPassword}
                    </small>
                  ) : null}
                </div>
              </div>
              {/*<div className="modifica-date-textInput-row">*/}
              {/*  <h2>Confirmă parola nouă</h2>*/}
              {/*  <TextInput*/}
              {/*    id={"confirmNewPassword"}*/}
              {/*    name={"confirmNewPassword"}*/}
              {/*    type={"text"}*/}
              {/*    placeholder={"Confirmă parola nouă"}*/}
              {/*    classNames={"modifica-date-modal-textInput"}*/}
              {/*    value={formik.values.confirmNewPassword}*/}
              {/*    error={formik.errors.confirmNewPassword}*/}
              {/*    touched={formik.touched.confirmNewPassword}*/}
              {/*    setFieldValue={formik.setFieldValue}*/}
              {/*  />*/}
              {/*</div>*/}
              <div className="modifica-date-textInput-row">
                <h2>Confirmă parola nouă</h2>
                <Password
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  placeholder="Confirmă parola nouă"
                  toggleMask
                  className="modifica-date-modal-textInput"
                  inputStyle={{ width: "100%" }}
                  value={formik.values.confirmNewPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.confirmNewPassword &&
                formik.errors.confirmNewPassword ? (
                  <small className="p-error">
                    {formik.errors.confirmNewPassword}
                  </small>
                ) : null}
              </div>
              <div className="modifica-date-modal-end-buttons">
                <SubmitButton
                  onClick={() => setShowModficaParola(false)}
                  classNames={"buton-bg-alb-green"}
                  text={"Inchide"}
                />
                <SubmitButton
                  onClick={() => setIsFormSubmitted(true)}
                  typeOfButton={"submit"}
                  text={"Salveaza"}
                />
              </div>
            </form>
          </div>
        </div>,
        document.getElementById("modifica-date-modal"),
      )}
    </>
  );
}
